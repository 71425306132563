 import {API_URL} from './axios-config';
import axios from 'axios'
export const getCsrfToken = async () => {
    try {
        const apiUrl = `${API_URL}api/tpservice/authsystaxpayer/csrf_token/`;
        const response = await axios.get(apiUrl); 
        const csrfToken = response.data.csrfToken;
        const metaTag = document.createElement('meta');
        metaTag.name = "csrf-token";
        metaTag.content = csrfToken;
        document.head.appendChild(metaTag);
               return csrfToken;
    } catch (error) {
        console.error('Inside csrf-token.js file, Error fetching CSRF token:', error);
        return null;
    }
};
