
import { locale, loadMessages } from 'devextreme/localization';
import enMessages from '@/localization/locales/en.json';
import amMessages from '@/localization/locales/am.json';

const messages = {
  en: enMessages,
  am: amMessages
};

export const setLocale = (langCode) => {
  if (messages[langCode]) {
    loadMessages(messages[langCode]);
    locale(langCode);
  } else {
    loadMessages(messages.en);
    locale('en');
  }
};

export const localize = (key) => {
  const currentLocale = locale();
  const messagesForLocale = messages[currentLocale] || messages.en;

  return messagesForLocale[key] || key;
};

export { enMessages, amMessages };
