
import UserDataManager from './user-roles';

// Function to get user roles (returns a promise)
function getUserRoles() {
  return new Promise((resolve) => {
    const userDataManager = new UserDataManager();
    const roles = userDataManager.getRoles();
    resolve(roles);
  });
}

const menuItems = [
  {
    text: "menu.Home",
    path: "/home",
    icon: "home",
    requiredRoles: [] // No specific role required
  },
    {
    text: "menu.Dashboard",
    path: "/dash-board",
    icon: "showpanel",
    requiredRoles: [] // No specific role required
  }, 
 {
    text: "menu.Taxpayer Detail",
    path: "/taxpayer-detail",
    icon: "showpanel",
    requiredRoles: [] // No specific role required
  }, 
 {
    text: "menu.Licence Renewal Letter",
    path: "/bussiness-licence-renewal",
    icon: "showpanel",
    requiredRoles: [] // No specific role required
  },
 {
    text: "menu.Payment Details",
    path: "/taxpayer-paymnet-info",
    icon: "showpanel",
    requiredRoles: [] // No specific role required
  }, 
 {
    text: "menu.Message Inbox",
    path: "/message-inbox",
    icon: "message",
    requiredRoles: [] // No specific role required
  }, 
   
  ];

// Function to filter menu items based on roles
function filterMenuItemsByRole(menuItems, roles) {
  return menuItems.reduce((filteredItems, item) => {
    const hasAccess = item.requiredRoles.length === 0 || item.requiredRoles.some(role => roles.includes(role));

    if (hasAccess) {
      if (item.items) {
        const nestedItems = filterMenuItemsByRole(item.items, roles);
        if (nestedItems.length > 0) {
          filteredItems.push({ ...item, items: nestedItems });
        }
      } else {
        filteredItems.push(item);
      }
    }

    return filteredItems;
  }, []);
}

// Function to get roles for a specific path
function getRolesForPath(path) {
  for (const item of menuItems) {
    if (item.path === path) {
      return item.requiredRoles || [];
    }
    if (item.items) {
      const nestedRoles = getRolesForPathFromItems(item.items, path);
      if (nestedRoles.length) {
        return nestedRoles;
      }
    }
  }
  return [];
}

function getRolesForPathFromItems(items, path) {
  for (const item of items) {
    if (item.path === path) {
      return item.requiredRoles || [];
    }
    if (item.items) {
      const nestedRoles = getRolesForPathFromItems(item.items, path);
      if (nestedRoles.length) {
        return nestedRoles;
      }
    }
  }
  return [];
}

// Export the filtering function as default
async function getVisibleMenuItems() {
  const roles = await getUserRoles();
  return filterMenuItemsByRole(menuItems, roles);
}

export default getVisibleMenuItems;
export { getRolesForPath };



