<template>
  <div class="data-grid-container">
    <div class="grid-container-header">
      <p>Home</p>
    </div>
    <div class="grid-box">
     Welcome to Electronic Tax Adminstration System
  </div>
</div>
</template>


