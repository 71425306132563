import axios from 'axios';
import { API_URL } from './axios-config';
import axiosInstance from './axios-config';

const defaultUser = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  roles: []
};

const auth = {
  _user: { ...defaultUser },
  async logIn(u, p) {
    try {
        const response = await axios.post(`${API_URL}api/tpservice/authsystaxpayer/login/`, 
            { u, p }, 
            { withCredentials: true }  
        );
        
        if (response && response.data) {
            const userData = response.data.user_data;
            localStorage.setItem('user_data', JSON.stringify(userData));
            this._user = { ...userData };
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        if (error.response && error.response.data) {
            const errorDetails = error.response.data.details;
            const errorMessage = Array.isArray(errorDetails.non_field_errors)
                ? errorDetails.non_field_errors[0]
                : 'Login failed. Please try again.';
            throw new Error(errorMessage);
        }

        throw new Error('Login failed. Please try again.');
    }
}
,

  async clearCookie(){
          document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          document.cookie = 'user_info=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          document.cookie = 'sliding_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          localStorage.removeItem('user_data');
          this._user = { ...defaultUser };
  },
  async logOut() {
    try {

      await axiosInstance.post('api/tpservice/authsystaxpayer/logout/');
      document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      document.cookie = 'user_info=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      document.cookie = 'sliding_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      localStorage.removeItem('user_data');
      this._user = { ...defaultUser };

    } catch (error) {
        document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        document.cookie = 'user_info=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        document.cookie = 'sliding_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        localStorage.removeItem('user_data');
        this._user = { ...defaultUser };
    }
  },

  async getUserData() {
    const userData = localStorage.getItem('user_data');
    if (userData) {
      try {
        const parsedUserData = JSON.parse(userData);
        this._user = { ...parsedUserData }; 
        return this._user;
      } catch (error) {
        return null;
      }
    }
    return null;
  },

  async getUser() {
    return this._user; 
  }
};

export default auth;




