// user-roles.js

export default class UserDataManager {
    constructor(storageKey = 'user_data') {
        this.storageKey = storageKey;
    }

    // Retrieve the entire user data object from local storage
    getUserData() {
        const userData = JSON.parse(localStorage.getItem(this.storageKey));
        return userData || {};
    }

    // Retrieve the list of roles from user data
    getRoles() {
        const userData = this.getUserData();
        return userData.roles || [];
    }

    // Retrieve a specific role by name from the roles list
    getRole(roleName) {
        const roles = this.getRoles();
        return roles.find(role => role === roleName) || null;
    }

    // Check if a specific role exists in the roles list
    roleExists(roleName) {
        return this.getRole(roleName) !== null;
    }

    // Retrieve a specific piece of user data by key
    getUserDataByKey(key) {
        const userData = this.getUserData();
        return userData[key] || null;
    }
}
