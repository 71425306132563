import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import customLayout from "./layouts/custom-layout";

import axios from 'axios';
import { API_URL } from "./axios-config";
import UserDataManager from './user-roles'; 

import Home from "./views/home-page";
import GenerateReceipt from "./views/taxpayerpages/generate-receipt.vue";
import BussinessLicenceRenewal from "./views/taxpayerpages/Bussiness_Licence_Renewal.vue";
import TaxpayerDetail from "./views/taxpayerpages/taxpayer-detail.vue";
import TaxpayerPaymnetInfo from "./views/taxpayerpages/taxpayer-paymnet-info.vue";
import Dashboard from "./views/taxpayerpages/dash-board.vue";
import MessageInbox from "./views/taxpayerpages/message-inbox.vue";

const userDataManager = new UserDataManager();

function loadView(view) {
  return () => import(/* webpackChunkName: "views" */ `./views/${view}.vue`);
}

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { 
      requiresAuth: true,
      layout: defaultLayout
     }
  },
   
  {
    path: '/login-form',
    name: 'login-form',
    component: loadView("login-form"),
    meta: { requiresAuth: false, 
      layout: customLayout,
       title: 'Amhara Bureau of Revenue',
       description: 'Electronic Tax Administration System (eTAS)'
       }
  },
  
  {
    path: '/change-password/:recoveryCode',
    name: 'change-password',
    component: loadView("change-password-form"),
    meta: { requiresAuth: false, layout: simpleLayout, title: 'Change Password' }
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/recovery",
    redirect: "/home"
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home"
  },

  {
    path: "/generate-reciept",
    name: "generate-reciept",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      // authorize: [Role.Admin] 

    },
    component: GenerateReceipt
  },
  {
    path: "/bussiness-licence-renewal",
    name: "bussiness-licence-renewal",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      // authorize: [Role.Admin] 

    },
    component: BussinessLicenceRenewal
  },
  {
    path: "/taxpayer-detail",
    name: "taxpayer-detail",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      // authorize: [Role.Admin] 

    },
    component: TaxpayerDetail
  },

  {
    path: "/taxpayer-paymnet-info",
    name: "taxpayer-paymnet-info",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      // authorize: [Role.Admin] 

    },
    component: TaxpayerPaymnetInfo
  },
  
  {
    path: "/dash-board",
    name: "dash-board",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      // authorize: [Role.Admin] 

    },
    component: Dashboard
  }, 

 {
    path: "/message-inbox",
    name: "message-inbox",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      // authorize: [Role.Admin] 

    },
    component: MessageInbox
  }, 
];

  const router = createRouter({
    history: createWebHashHistory(),
    routes,
  });
  
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      try {
        const response = await axios.get(`${API_URL}api/tpservice/authsystaxpayer/check-auth/`, { withCredentials: true });
  
        if (response.status === 200) {
          const newUserData = response.data.user_data;
          const newUserRoles = newUserData.roles;
          const storedUserRoles = JSON.parse(localStorage.getItem('user_data'))?.roles || [];
  
          if (JSON.stringify(newUserRoles) !== JSON.stringify(storedUserRoles)) {
            localStorage.setItem('user_data', JSON.stringify(newUserData));
            userDataManager.setRoles(newUserRoles);
            window.dispatchEvent(new Event('roles-updated')); // Notify menu component
          }
          next();
        } else {
          await auth.clearCookie();
          next({ name: 'login-form', query: { redirect: to.fullPath } });
        }
      } catch (error) {
        await auth.clearCookie();
        next({ name: 'login-form', query: { redirect: to.fullPath } });
      }
    } else {
      next();
    }
  });
  export default router;
  