<template>
  <div class="taxpayer-details-card">
    <div class="border-div">
      <h2 class="header-title">የከፍያ ማረጋገጫ ደብዳቤዎች</h2>
    </div>
    <div class="form-group">
      <div class="form-column">
        <div class="form-item">
          <label for="tin">TIN:</label>
          <span id="tin">{{ taxpayerInfo.tin_number }}</span>
        </div>
        <div class="form-item">
          <label for="name">Full Name:</label>
          <span id="name">{{ taxpayerInfo.taxpayer_name }}</span>
        </div>


      </div>

      <div class="form-column">

        <!-- <div class="form-item">
          <label for="email">Email Address:</label>
          <span id="email">{{ taxpayerInfo.email_address }}</span>
        </div> -->
        <div class="form-item">
          <label for="tax_center">Tax Center:</label>
          <span id="tax_center">{{ taxpayerInfo.tax_center }}</span>
        </div>
        <div class="form-item">
          <label for="business_activity">Business Name:</label>
          <span id="business_activity">{{ taxpayerInfo.trade_name }}</span>
        </div>

      </div>

      <div class="form-column">
        <div class="form-item">
          <label for="fiscal_year">Current Fiscal Year:</label>
          <span id="fiscal_year">{{ taxpayerInfo.fiscal_year }}</span>
        </div>




      </div>
    </div>

    <div class="combo-year">
      <div class="select-container">
        <label for="letter_type" class="select-label">Select Letter Type:</label>

        <DxSelectBox
            v-model="letterType"
            :items="letterData"
            display-expr="letter_type_name"
            value-expr="letter_type_code"
            placeholder="Select Region"
            @valueChanged="handleValueChange"
          ></DxSelectBox>



      </div>
    </div>


    
<!-- 
    <div v-if="selectedLetterType">
      <p>Selected Letter Type Code: {{ selectedLetterType.letter_type_code }}</p>
      <p>Selected Letter Type Name: {{ selectedLetterType.letter_type_name }}</p>
    </div> -->


    <div v-if="showPreview" style="padding: 20px;">
      <span style="color: blue;" v-if="st_payment_completed && mf_payment_completed">{{ completeneddMessage }}</span>
      <span style="color: red;" v-else>{{ notCompletedMessage }}</span>
      <DxButton text="Download Letter" @click="generateLetter" :style="{ backgroundColor: '#368fddc2' }"
        :disabled="!(st_payment_completed && mf_payment_completed && selectedLetterCode)" />
    </div>

    <div v-if="loading">Generating letter number...</div>
    <div v-if="message" :class="{ 'error-message': isError }">{{ message }}</div>

  </div>
</template>

<script setup>


const showPreview = ref(true);
import { ref, onMounted } from 'vue';
import axiosInstance from '@/axios-config';
import { DxButton } from 'devextreme-vue';
import DxSelectBox from 'devextreme-vue/select-box';
// import { saveAs } from 'file-saver';

import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import logo from '@/assets/aborlogo.png';
import stumplogo from '@/assets/aborstump.png';
import NotoSansEthiopic from '@/assets/fonts/NotoSansEthiopic-Regular.ttf';

const taxpayerInfo = ref({
  tin: '',
  taxpayer_full_name: '',
  tax_center_name: '',
  revenu_business_activity_name: '',
  calendar_type: '',
  taxpayer_category_name: '',
  taxpayer_status: ''
});

const completeneddMessage = "የሚጠበቅብዎትን ክፍያ ስላጠናቅቁ እናምሰግናለን። የእድሳት ደብዳቤ እዚህ ያውርዱ  ";
const notCompletedMessage = "የእድሳት ደብዳቤ ለማውረድ  የሚጠበቅብዎትን ክፍያ ያጠናቁ!!!";


const letterType = ref();    // Holds the selected letter type
const selectedLetterType = ref(null); // To store the selected letter type details
const letterData = ref([]);


const generatedDate = ref(new Date().toLocaleDateString());
const letterNumberGenerated = ref('');
const taxCenter = ref('');
const taxpayername = ref('');
const tinNumber = ref('');
const tradeName = ref('');
const fiscalYear = ref('');
const licenceRenewalYear = ref('');
const qrCodeDataURL = ref('');
const receiptNumber = ref('');
const st_payment_completed = ref('');
const mf_payment_completed = ref('');
const phone_number = ref('');

const varcode = ref('');

const message = ref('');
const loading = ref(false);
const isError = ref(false);



const fetchTaxpayerInfo = async () => {
  


  try {
    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_info/`);
    const data = response.data;

    if (data.length > 0) {
      const taxpayerData = data[0];
      taxpayerInfo.value = taxpayerData;

      //letterNumber.value = taxpayerData.letter_number;
      taxCenter.value = taxpayerData.tax_center || '';
      taxpayername.value = taxpayerData.taxpayer_name || '';
      tinNumber.value = taxpayerData.tin_number || '';
      tradeName.value = taxpayerData.trade_name || '';
      fiscalYear.value = taxpayerData.fiscal_year || '';
      licenceRenewalYear.value = taxpayerData.licence_renewal_year || '';
      receiptNumber.value = taxpayerData.receipt_number || '';
      varcode.value = taxpayerData.receipt_varcode || '';
      phone_number.value = taxpayerData.mobile_phone_number || '';
      st_payment_completed.value = taxpayerData.st_is_bill_completed || '';
      mf_payment_completed.value = taxpayerData.mf_is_bill_completed || '';



    }
  } catch (error) {
    console.error('Error fetching taxpayer info:', error);
  }
};




// Fetch letter types from API
const fetchLetterTypes = async () => {
  try {
    const response = await axiosInstance.get('api/tpservice/taxpayer_service/get-letter-types/');
    // Make sure response.data is an array
    letterData.value = response.data;

    // console.log('fetchLetterTypes data:', letterData.value);
  } catch (error) {
    console.error('Error fetching letter types:', error);
  }
};

const generateLetterNumber = async () => {
  // Reset states before making the request
  loading.value = true;
  message.value = '';
  isError.value = false;

  try {
    // Sending a POST request to the Django API
    const response = await axiosInstance.post('api/tpservice/taxpayer_service/insert_generated_letter_number/', {
      letter_type_code: selectedLetterType.value.letter_type_code,
      fiscal_year: fiscalYear.value,
    });

    const letterNumberData=response.data;
    letterNumberGenerated.value= letterNumberData.letter_number;
     
    // console.log('generateLetterNumber data:', letterNumberGenerated.value);
    // Handling the response based on status
    if (response.status === 200) {
      message.value = response.data.message; // Success message from the API
    } else {
      message.value = 'An unexpected error occurred.';
      isError.value = true;
    }
  } catch (error) {
    // Handling errors and displaying an error message
    message.value = `Error: ${error.response?.data.error || error.message}`;
    isError.value = true;
  } finally {
    // Reset loading state after request completes
    loading.value = false;
  }
};




const selectedLetterCode=ref(null);

const handleValueChange = (e) => {
   selectedLetterCode.value = e.value;
  const selectedOption = letterData.value.find(option => option.letter_type_code === selectedLetterCode.value);
  
  // Update selectedLetterType with the selected option
  selectedLetterType.value = selectedOption;

};


const generateLetter = async () => {
  try {
    // Get the letter Number
    await generateLetterNumber();
    const response = await fetch(NotoSansEthiopic);
    const font = await response.arrayBuffer();
    const fontData = btoa(String.fromCharCode(...new Uint8Array(font)));

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.setLineHeightFactor(1.5);
    doc.addFileToVFS('NotoSansEthiopic-Regular.ttf', fontData);
    doc.addFont('NotoSansEthiopic-Regular.ttf', 'NotoSansEthiopic', 'normal');
    doc.setFont('NotoSansEthiopic');

    const docWidth = doc.internal.pageSize.width;
    const pageWidth = doc.internal.pageSize.getWidth();
    const qrCodeSize = 20;

    if (!qrCodeDataURL.value) {
      await generateQRCode();
    }

    // Add QR Code
    doc.addImage(qrCodeDataURL.value, 'PNG', pageWidth - qrCodeSize - 10, 20, qrCodeSize, qrCodeSize);

    // Add Logo
    const imgData = logo;
    if (imgData) {
      doc.addImage(imgData, 'PNG', 10, 10, 60, 20);
    } else {
      console.error('Logo image data is missing');
    }

    // Title
    const certificateTitle = 'የንግድ ፈቃድ ለማደስ የሚሰጥ ማረጋገጫ';
    doc.setFontSize(16);
    doc.text(certificateTitle, docWidth / 2, 40, { align: 'center' });

    // Date and Letter Number
    const dateText = `ቀን (Generated Date): ${generatedDate.value}`;
    const letterNumberText = `ቁጥር (Ref. Number): ${letterNumberGenerated.value}`;
    doc.setFontSize(12);
    doc.text(dateText, docWidth - 20, 60, { align: 'right' });
    doc.text(letterNumberText, docWidth - 20, 70, { align: 'right' });

    // Tax Center
    const taxCenterText = `የ ${taxCenter.value} ገቢዎች መምሪያ/ጽ/ቤት`;
    doc.text(taxCenterText, docWidth / 2, 90, { align: 'center' });

    // Tax Confirmation Title with Underline
    const taxConfirmationText = `የንግድ ፈቃድ ለማደስ በገቢ ተቋማት የሚሰጥ የታክስ ክፍያ ማረጋገጫ የምስክር ወረቀት`;
    const y = 110;
    doc.setFontSize(14);
    doc.text(taxConfirmationText, docWidth / 2, y, { align: 'center' });

    const textWidth = doc.getTextWidth(taxConfirmationText);
    const underlineY = y + 2;

    doc.setDrawColor(0);
    doc.line(
      (docWidth / 2) - (textWidth / 2),
      underlineY,
      (docWidth / 2) + (textWidth / 2),
      underlineY
    );

    // Taxpayer Info
    const taxpayerInfoText = `አቶ/ወ/ሮ/ሪት ${taxpayername.value} በግብር ከፋይ መለያ ቁጥር ${tinNumber.value} በ ${tradeName.value} ንግድ ሥራ ፈቃድ የሚታወቁት የ ${fiscalYear.value} ግብር ዘመን የሚፈለግባቸውን የታክስ ግዴታ የተወጡ በመሆኑ የ ${licenceRenewalYear.value} ዓ.ም የንግድ ፈቃድ ለማደስ ይህ የታክስ ክፍያ ማረጋገጫ የምስክር ወረቀት የተሰጣቸው መሆኑን እናሳስባለን፡፡`;
    const taxpayerInfoLines = doc.splitTextToSize(taxpayerInfoText, 200);
    doc.setFontSize(12);
    doc.text(taxpayerInfoLines, 20, 120); // Adjusted Y position to fit the content

    // Closing Text
    const closeText = `“ከሠላምታ ጋር”\n`;
    doc.text(closeText, docWidth - 20, 150, { align: 'right' });

    // Note
    const noteText = `ማሳሰቢያ፡- ይህ የታክስ ክፍያ ማረጋገጫ የምስክር ወረቀት የሚያገለግለው ለአንድ ዓመት ብቻ ሲሆን የተሰጠዎ ማስረጃም ተቋሙ ሌሎች ተጨማሪ መረጃዎች ካገኘ እንደገና ወስኖ ለማስከፈል መብቱ የተጠበቀ መሆኑ ለመግለጽ እንወዳለን።`;
    const noteTextLines = doc.splitTextToSize(noteText, 180);
    doc.text(noteTextLines, 20, 220);

    // Stump Image
    const stumpImg = stumplogo;
    if (stumpImg) {
      doc.addImage(stumpImg, 'PNG', 90, 160, 40, 40);
    } else {
      console.error('Stump image data is missing');
    }

    // Use FileSaver.js to handle saving the PDF on both desktop and mobile
    const pdfBlob = doc.output('blob');

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveOrOpenBlob(pdfBlob, 'tax_confirmation_certificate.pdf');
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = 'tax_confirmation_certificate.pdf';

      // Append the anchor to the body
      document.body.appendChild(link);
      link.click();

      // Remove the anchor from the body
      document.body.removeChild(link);
    }
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};


async function generateQRCode() {
  try {
    const qrContent = `
      TIN Number: ${tinNumber.value} \n 
      Full Name: ${taxpayername.value}\n
      Code: ${varcode.value} 

    `;
    const qrCodeURL = await QRCode.toDataURL(qrContent); // Use actual data for the QR code
    qrCodeDataURL.value = qrCodeURL;
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
}



onMounted(() => {
  fetchLetterTypes();
  fetchTaxpayerInfo();

});
</script>




<style scoped>
.taxpayer-details-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
  max-width: 100%;
}

.header-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.border-div {
  border: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 2px;
}

.form-column {
  width: 100%;
  margin-bottom: 20px;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
  font-size: 16px;
}

span {
  padding: 8px 12px;
  border-radius: 4px;
  color: #333;
  font-size: 16px;
}

.select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
}

.combo-year {
  margin: 20px 0;
}

.dx-selectbox {
  width: 100%;
}

@media (min-width: 768px) {
  .form-column {
    width: 48%;
  }
  
  .select-container {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .form-group {
    flex-wrap: nowrap;
  }

  .form-column {
    width: 30%;
  }

  .select-container {
    width: 500px;
    display: -webkit-inline-box;
  }

  .form-item {
    flex-direction: row;
    align-items: center;
  }

  label {
    min-width: 140px;
    margin-right: 10px;
    text-align: right;
  }

  span {
    flex: 1;
  }
}
</style>

