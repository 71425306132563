<template>
  <div class="language-selector">
    <div class="select-wrapper">
      <select v-model="selectedLanguage" @change="handleLanguageChange" class="select-language">
        <option value="en">🇬🇧 English</option>
        <option value="am">🇪🇹 አማርኛ</option>
      </select>
      <span class="custom-arrow">▼</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: localStorage.getItem('selectedLanguage') || 'en'
    };
  },
  methods: {
    handleLanguageChange() {
      localStorage.setItem('selectedLanguage', this.selectedLanguage);
      this.$emit('language-change', this.selectedLanguage);
      // Reload the page to apply changes everywhere
      window.location.reload();
    }
  }
};
</script>

<style scoped>

.select-wrapper {
  position: relative; 
  display: inline-block;
  width: 100%;
}

.select-language {
  width: 100%;
  padding: 5px; 
  font-size: 14px; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  background-color: #fff; 
  color: #333; 
  cursor: pointer; 
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none;
}

.custom-arrow {
  position: absolute;
  top: 60%;
  right: 10px; 
  pointer-events: none; 
  transform: translateY(-50%);
  font-size: 14px; 
  color: #333; 
}
</style>

