<template>
  <div class="taxpayer-details-card">
    <div class="border-div">
      <h2 class="header-title">የግብር ከፍይ ዝርዝር መረጃ</h2>
    </div>

    <div class="form-group">
      <div class="form-column">
        <div class="form-item">
          <label for="tin">TIN:</label>
          <span id="tin">{{ taxpayer.tin }}</span>
        </div>
        <div class="form-item">
          <label for="name">Full Name:</label>
          <span id="name">{{ taxpayer.taxpayer_full_name }}</span>
        </div>
        <div class="form-item">
          <label for="tax_center">Tax Center:</label>
          <span id="tax_center">{{ taxpayer.tax_center_name }}</span>
        </div>
        <div class="form-item">
          <label for="place_address">Place Address:</label>
          <span id="place_address">{{ taxpayer.place_name }}</span>
        </div>
      </div>

      <div class="form-column">
        <div class="form-item">
          <label for="phone">Mobile Phone:</label>
          <span id="phone">{{ taxpayer.mobile_phone_number }}</span>
        </div>
        <div class="form-item">
          <label for="email">Email Address:</label>
          <span id="email">{{ taxpayer.email_address }}</span>
        </div>
        <div class="form-item">
          <label for="business_activity">Business Name:</label>
          <span id="business_activity">{{ taxpayer.revenu_business_activity_name }}</span>
        </div>
        <div class="form-item">
          <label for="calendar_type">Calendar Type:</label>
          <span id="calendar_type">{{ taxpayer.calendar_type }}</span>
        </div>
      </div>

      <div class="form-column">
        <div class="form-item">
          <label for="owner_type">Owner Type:</label>
          <span id="owner_type">{{ taxpayer.owner_type_name }}</span>
        </div>
        <div class="form-item">
          <label for="taxpayer_category">Taxpayer Category:</label>
          <span id="taxpayer_category">{{ taxpayer.taxpayer_category_name }}</span>
        </div>
        <div class="form-item">
          <label for="status">Taxpayer Status:</label>
          <span id="status">{{ taxpayer.taxpayer_status }}</span>
        </div>
        <div class="form-item">
          <label for="established_year">Established Year:</label>
          <span id="established_year">{{ taxpayer.established_year }}</span>
        </div>
      </div>
    </div>

    <dx-button 
      class="details-button"
      :text="'Show Details'" 
      :disabled="!isButtonEnabled"  
      @click="displayDetails">
    </dx-button>

  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axiosInstance from '@/axios-config';


const taxpayer = ref({});
const taxpayerType = ref('');

const fetchTaxpayerDetails = async () => {
  try {
    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_basic_info/`);
    taxpayer.value = response.data[0] || {}; 

    if (taxpayer.value.owner_type_name === 'Individual') {
      taxpayerType.value = 'Individual';
    } else if (taxpayer.value.owner_type_name === 'Non-Individual') {
      taxpayerType.value = 'Non-Individual';
    } else {
      taxpayerType.value = ''; 
    }
  } catch (error) {
    console.error('Error fetching taxpayer details:', error);
  }
};

const isButtonEnabled = computed(() => {
  return taxpayerType.value === 'Individual' || taxpayerType.value === 'Non-Individual';
});

const displayDetails = () => {
  if (isButtonEnabled.value) {
    console.log('Displaying details for:', taxpayerType.value);
  } else {
    console.log('Button is disabled, no action taken.');
  }
};

onMounted(() => {
  fetchTaxpayerDetails();
});
</script>

<style scoped>
.taxpayer-details-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.header-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.border-div {
  border: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 2px;
}

.form-column {
  width: 30%;
  margin-bottom: 20px;
}

.form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #555;
  margin-right: 10px;
  min-width: 140px;
  text-align: right;
  font-size: 16px;
}

span {
  padding: 8px 12px;
  color: #333;
  flex: 1;
}

.details-button:hover {
  background-color: #218838;
}

.details-info {
  margin-top: 30px;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.details-info h3 {
  margin-bottom: 10px;
  color: #007bff;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .form-column {
    width: 45%; /* Adjust width for medium screens */
  }
}

@media (max-width: 768px) {
  .form-column {
    width: 100%; /* Stack columns on small screens */
  }

  .form-item {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    text-align: left;
    margin-bottom: 5px;
  }

  span {
    width: 100%;
  }
  .dx-widget {
	font-size: 16px;
}
}
</style>
