import 'devextreme/dist/css/dx.light.css';  // Default theme
import './themes/generated/theme.base.css';
import './themes/css/style.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";
import { getCsrfToken } from './csrf-token';  // Import the CSRF function 


import config from 'devextreme/core/config'; 
import { licenseKey } from '@/devextreme-license'; 
 
config({ licenseKey });   

import App from "./App";
import appInfo from "./app-info";

themes.initialized(async() => {
    await getCsrfToken();  // Fetch and store the CSRF token before mounting the app
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});