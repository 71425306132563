
<template>
  <div class="taxpayer-details-card">
    <div class="border-div">
      <h2 class="header-title">የከፍያ ዝርዝር መግልጫ</h2>
    </div>
    <div class="form-group">
      <div class="form-column">
        <div class="form-item">
          <label for="tin">TIN:</label>
          <span id="tin">{{ taxpayerInfo.tin_number }}</span>
        </div>
        <div class="form-item">
          <label for="name">Full Name:</label>
          <span id="name">{{ taxpayerInfo.taxpayer_name }}</span>
        </div>
      </div>

      <div class="form-column">
        <div class="form-item">
          <label for="tax_center">Tax Center:</label>
          <span id="tax_center">{{ taxpayerInfo.tax_center }}</span>
        </div>
        <div class="form-item">
          <label for="business_activity">Business Name:</label>
          <span id="business_activity">{{ taxpayerInfo.trade_name }}</span>
        </div>
      </div>

      <div class="form-column">
        <div class="form-item">
          <label for="fiscal_year">Current Fiscal Year:</label>
          <span id="fiscal_year">{{ taxpayerInfo.fiscal_year }}</span>
        </div>
      </div>
    </div>

    <!-- State Tax DxDataGrid component -->
    <div class="grid-box">
      <div class="data-grid-container">
        <h6 class="data-grid-title">State Tax Payment Details</h6>
        <DxDataGrid id="stgrid" gridIdentifier="stgrid" class="dx-card wide-card" :dataSource="selectedPaymentDetails" :showBorders="true"
          :showColumnLines="true" :showRowLines="true" :row-alternation-enabled="true" :remote-operations="false"
          :focused-row-enabled="true" key-expr="st_receiptNumber"
          :master-detail="{ enabled: true, template: 'stdetail' }"
          :v-model:selected-row-keys="selectedTaxpayerIds"
          :selection="{ mode: 'single', allowSelectAll: false }"
          detailExpandMode="single"
          @selectionChanged="onSelectionChanged"
          @rowExpanding="onRowExpanding"
          @rowExpanded="onRowExpanded">
          <DxPaging :page-size="10" />
          <DxPager :visible="true" :allowed-page-sizes="[5, 10, 20, 50]" :show-page-size-selector="true"
            :show-info="true" :show-navigation-buttons="true" />
          <DxColumn :dataField="'st_fiscalYear'" :caption="'Fiscal Year'" />
          <DxColumn :dataField="'total_state_tax_amount'" :caption="'Total Tax'" />
          <DxColumn :dataField="'total_state_tax_paid_amount'" :caption="'Total Paid'" />
          <DxColumn :dataField="'state_tax_bill_code'" :caption="'Bill Code'" />
          <DxColumn :dataField="'state_tax_payment_date'" :caption="'Payment Date'" />
          <DxColumn :dataField="'st_receiptNumber'" :caption="'Receipt Number'" />
          <DxColumn :dataField="'stateTaxNotPaid'" :caption="'Unpaid '" />
          <DxColumn :cell-template="st_actionsCellTemplate" />
          <!-- Detail Grid Template -->
          <template #stdetail>
            <DxDataGrid :data-source="taxpayerDetailData" :show-borders="true" :remote-operations="false"
              :row-alternation-enabled="true">
              <DxColumn dataField="account_code" caption="የሂሳብ መደብ (Account Code)" dataType="string" :width="300"/>
              <DxColumn dataField="debit" caption="Amount" dataType="number" />
            </DxDataGrid>
          </template>
        </DxDataGrid>

        <!-- Municipality DataGrid component -->
        <h6 class="data-grid-title">Municipality Payment Details</h6>
        <DxDataGrid id="mfgrid" gridIdentifier="mfgrid" class="dx-card wide-card" :dataSource="selectedPaymentDetails" :showBorders="true"
          :showColumnLines="true" :showRowLines="true" :row-alternation-enabled="true" :remote-operations="false"
          :focused-row-enabled="true" key-expr="mf_receiptNumber"
          :master-detail="{ enabled: true, template: 'mfdetail' }"
          :v-model:selected-row-keys="selectedTaxpayerIds"
          :selection="{ mode: 'single', allowSelectAll: false }"
          detailExpandMode="single"
          @selectionChanged="onSelectionChanged"
          @rowExpanding="onRowExpanding"
          @rowExpanded="onRowExpanded">
          <DxPaging :page-size="10" />
          <DxPager :visible="true" :allowed-page-sizes="[5, 10, 20, 50]" :show-page-size-selector="true"
            :show-info="true" :show-navigation-buttons="true" />
          <DxColumn :dataField="'mf_fiscalYear'" :caption="'Fiscal Year'" />
          <DxColumn :dataField="'total_municipality_tax_amount'" :caption="'Total Tax'" />
          <DxColumn :dataField="'totalMunicipalityTaxPaidAmount'" :caption="'Total Paid'" />
          <DxColumn :dataField="'municipality_tax_bill_code'" :caption="'Bill Code'" />
          <DxColumn :dataField="'municipality_payment_date'" :caption="'Payment Date'" />
          <DxColumn :dataField="'mf_receiptNumber'" :caption="'Receipt Number'" />
          <DxColumn :dataField="'municipalityTaxNotPaid'" :caption="'Unpaid '" />
          <DxColumn :cell-template="mf_actionsCellTemplate" />
          <!-- Detail Grid Template -->
          <template #mfdetail>
            <DxDataGrid :data-source="taxpayerDetailData" :show-borders="true" :remote-operations="false"
              :row-alternation-enabled="true">
              <DxColumn dataField="account_code" caption="የሂሳብ መደብ (Account Code)" dataType="string" :width="300"/>
              <DxColumn dataField="debit" caption="Amount" dataType="number" />
            </DxDataGrid>
          </template>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>



<script setup>
import { ref, onMounted } from 'vue';

import 'devextreme/dist/css/dx.light.css';
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  
} from 'devextreme-vue/data-grid';
// import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxButton } from 'devextreme-vue/button';
import { createApp, h } from 'vue'; // Import createApp and h from Vue
import axiosInstance from '@/axios-config';

import logo from '@/assets/aborlogo.png';
import stumplogo from '@/assets/aborstump.png';
import NotoSansEthiopic from '@/assets/fonts/NotoSansEthiopic-Regular.ttf';
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import notify from 'devextreme/ui/notify';


const selectedPaymentDetails = ref([]);
const taxpayerInfo = ref({});
const fiscalYear = ref('');
const documentNumber = ref('');

const taxCenter = ref('');
const taxpayername = ref('');
const tinNumber = ref('');

const qrCodeDataURL = ref('');

const paymentReason = ref(' አመታዊ የስራ ግብር ክፍያ');
const paymentDate = ref('');

const barcode = ref('');
const taxpayerDetailData = ref('');
const bill_code=ref('');

const fetchPaymentDetails = async () => {
  try {

    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_info/`);
    const taxpayerData = response.data;

    taxpayerInfo.value = taxpayerData[0];
    fiscalYear.value = taxpayerInfo.value.fiscal_year;

 

    selectedPaymentDetails.value = taxpayerData.map(detail => {

      const totalStateTaxAmount = detail.state_tax_total;
      const totalMunicipalityTaxAmount = detail.municipality_fee_total;
      const totalStateTaxPaidAmount = detail.state_tax_paid_amount;
      const totalMunicipalityTaxPaidAmount = detail.municipality_tax_paid_amount;

      const totalTaxAmount = totalStateTaxAmount + totalMunicipalityTaxAmount;

      const totalTaxPaid = totalStateTaxPaidAmount + totalMunicipalityTaxPaidAmount;

      const totalTaxNotPaid = totalTaxAmount - totalTaxPaid;
      const stateTaxNotPaid=totalStateTaxAmount-totalStateTaxPaidAmount
      const municipalityTaxNotPaid=totalMunicipalityTaxAmount-totalMunicipalityTaxPaidAmount

      const st_receiptNumber = detail.st_receipt_number;
      const mf_receiptNumber = detail.mf_receipt_number;
      const total_state_tax_arrears = detail.state_tax_arrears;
      const total_municipality_tax_arrears = detail.municipality_arrears;
      const municipality_payment_date = detail.mf_payment_date;
      const state_tax_payment_date = detail.st_payment_date;
      const state_tax_bill_code = detail.taxpayer_state_tax_bill_code;
      const municipality_tax_bill_code = detail.taxpayer_municipality_fee_bill_code;
      const st_fiscalYear = detail.fiscal_year;
      const mf_fiscalYear = detail.fiscal_year;
      

      return {
        taxpayerId: detail.taxpayer_id,
        tin: detail.tin_number,
        fullName: detail.taxpayer_name,
        st_fiscalYear: st_fiscalYear,
        mf_fiscalYear: mf_fiscalYear,
        
        total_state_tax_amount: totalStateTaxAmount,
        total_municipality_tax_amount: totalMunicipalityTaxAmount,
        total_state_tax_paid_amount: totalStateTaxPaidAmount,
        totalMunicipalityTaxPaidAmount: totalMunicipalityTaxPaidAmount,
        totalTaxAmount: totalTaxAmount,
        totalTaxPaid: totalTaxPaid,
        totalTaxNotPaid: totalTaxNotPaid,
        st_receiptNumber: st_receiptNumber,
        mf_receiptNumber: mf_receiptNumber,
        total_state_tax_arrears: total_state_tax_arrears,
        total_municipality_tax_arrears: total_municipality_tax_arrears,
        municipality_payment_date: municipality_payment_date,
        state_tax_payment_date: state_tax_payment_date,
        state_tax_bill_code: state_tax_bill_code,
        municipality_tax_bill_code: municipality_tax_bill_code,
        municipalityTaxNotPaid:municipalityTaxNotPaid,
        stateTaxNotPaid:stateTaxNotPaid,
      

      };
    });
  } catch (error) {
    // notify('Error fetching Payment Details', 'error');
  }
};

onMounted(() => {
  fetchPaymentDetails(); // Initial fetch on component mount
});


const onRowExpanding = async (event) => {
  // Collapse all rows except the one being expanded
  event.component.collapseAll(-1);

  // Use a custom option to identify the grid
  const gridIdentifier = event.component.option('gridIdentifier');
 
  let rowData;

  // Handle data differently based on the grid identifier
  if (gridIdentifier === "stgrid") {  // For state tax grid
    rowData = selectedPaymentDetails.value.find(detail => detail.st_receiptNumber === event.key);
    checkReceiptNumber.value = rowData.st_receiptNumber;
    // console.log('Expanding stgrid:', checkReceiptNumber.value);
    await fetchTaxAccountData();
  } else if (gridIdentifier === "mfgrid") {  // For municipality fee grid
    rowData = selectedPaymentDetails.value.find(detail => detail.mf_receiptNumber === event.key);
    checkReceiptNumber.value=rowData.mf_receiptNumber
    await fetchTaxAccountData();
  }

  // console.log('Expanding Receipt_Number:', rowData.st_receiptNumber);



};



const onSelectionChanged = (e) => {
  // Collapse all rows when selection changes
  e.component.collapseAll(-1);

  // Expand the currently selected row
  if (e.currentSelectedRowKeys.length > 0) {
    const selectedKey = e.currentSelectedRowKeys[0];
    e.component.expandRow(selectedKey);

    // Determine the grid type (stgrid or mfgrid)
    const gridIdentifier = e.component.option('gridIdentifier');
    console.log('Selected gridIdentifier:', gridIdentifier);

    let rowData = null;

    if (gridIdentifier === "stgrid") {
      // For state tax grid
      rowData = selectedPaymentDetails.value.find(detail => detail.st_receiptNumber === selectedKey);

      if (rowData) {
        checkReceiptNumber.value = rowData.st_receiptNumber;
        fetchTaxAccountData();  // Fetch and display data related to state tax
      }

    } else if (gridIdentifier === "mfgrid") {
      // For municipality fee grid
      rowData = selectedPaymentDetails.value.find(detail => detail.mf_receiptNumber === selectedKey);

      if (rowData) {
        checkReceiptNumber.value = rowData.mf_receiptNumber;
        fetchTaxAccountData();  // Fetch and display data related to municipality fee
      }
    }

    // if (rowData) {
    //   console.log('Row Data:', rowData);
    // } else {
    //   console.error('No matching row data found for the selected key:', selectedKey);
    // }

  } else {
    console.error('No row selected');
  }
};


const onRowExpanded = async (event) => {


const gridIdentifier = event.component.option('gridIdentifier');

let rowData;

// Handle data differently based on the grid identifier
if (gridIdentifier === "stgrid") {  // For state tax grid
  rowData = selectedPaymentDetails.value.find(detail => detail.st_receiptNumber === event.key);
  checkReceiptNumber.value = rowData.st_receiptNumber;
  await fetchTaxAccountData();
} else if (gridIdentifier === "mfgrid") {  // For municipality fee grid
  rowData = selectedPaymentDetails.value.find(detail => detail.mf_receiptNumber === event.key);
  checkReceiptNumber.value=rowData.mf_receiptNumber
  await fetchTaxAccountData();
}
// console.log('Expanded Receipt_Number:', rowData.mf_receiptNumber);
};



// Fetch tax account data
const fetchTaxAccountData = async () => {
if(checkReceiptNumber.value !==null) {

  // console.log('Receipt:',checkReceiptNumber.value );

  const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_payment/${fiscalYear.value}/${checkReceiptNumber.value}/`);
    // console.log('pivate data:',response.data );
    const responseData=response.data
    taxpayerDetailData.value=responseData;
  return response.data;
}
else{
  
  return '';
}
}



//  State Tax Button Handler

function st_actionsCellTemplate(cellElement, cellInfo) {
  cellElement.innerHTML = '';
  bill_code.value=taxpayerInfo.value.taxpayer_state_tax_bill_code;
   const buttonApp = createApp({
    render() {
      return h(DxButton, {
        text: 'Receipt',
        type: 'default',
        width: 180,
        disabled: !cellInfo.data.st_receiptNumber,
        onClick: () => generateReceipt(cellInfo.data,'st'),

      });
    }
  });

  buttonApp.mount(cellElement);
}

//  Municipality Button Handler
function mf_actionsCellTemplate(cellElement, cellInfo) {
  cellElement.innerHTML = '';
  bill_code.value=taxpayerInfo.value.taxpayer_municipality_fee_bill_code;
  const buttonApp = createApp({
    render() {
      return h(DxButton, {
        text: 'Receipt',
        type: 'default',
        width: 180,
 
        disabled: !cellInfo.data.mf_receiptNumber,
        onClick: () => generateReceipt(cellInfo.data,'mf')
      });
    }
  });

  buttonApp.mount(cellElement);
}




const checkReceiptNumber = ref();


///////////  Generate generateReceipt ////////



async function generateReceipt(rowData, tax_group) {
  try {
    const rowDataAll = rowData;
    if (tax_group === 'st' && rowDataAll.st_receiptNumber !== null) {
      checkReceiptNumber.value = rowDataAll.st_receiptNumber;
      paymentDate.value = taxpayerInfo.value.st_payment_date;
    } else if (tax_group === 'mf' && rowDataAll.mf_receiptNumber !== null) {
      checkReceiptNumber.value = rowDataAll.mf_receiptNumber;
      paymentDate.value = taxpayerInfo.value.mf_payment_date;
    } else {
      checkReceiptNumber.value = '';
    }

    const response = await fetch(NotoSansEthiopic);
    const font = await response.arrayBuffer();
    const fontData = btoa(String.fromCharCode(...new Uint8Array(font)));

    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.setLineHeightFactor(1);
    doc.addFileToVFS('NotoSansEthiopic-Regular.ttf', fontData);
    doc.addFont('NotoSansEthiopic-Regular.ttf', 'NotoSansEthiopic', 'normal');
    doc.setFont('NotoSansEthiopic');
    const docWidth = doc.internal.pageSize.width;
    const pageWidth = doc.internal.pageSize.getWidth();
    const qrCodeSize = 20;

    if (!qrCodeDataURL.value) {
      await generateQRCode();
    }
    doc.addImage(qrCodeDataURL.value, 'PNG', pageWidth - qrCodeSize - 10, 10, qrCodeSize, qrCodeSize);

    const imgData = logo;
    if (imgData) {
      doc.addImage(imgData, 'PNG', 10, 10, 60, 20);
    } else {
      console.error('Logo image data is missing');
    }

    const taxCenterText = `\nየ ${taxCenter.value} ገቢዎች መምሪያ/ጽ/ቤት`;
    const certificateTitle = 'የኤሌክትሮኒክስ ክፍያ ደረሰኝ\n';
    const paymentCodeText = `የመክፈያ ኮድ(Payment Code): ${bill_code.value}`;
    const documentNumberText = `የዶክመንት ቁጥር (Document Number): ${documentNumber.value}`;
    const receiptNumberText = `የደረሰኝ ቁጥር (Receipt Number): ${checkReceiptNumber.value}`;
    const taxCenterConfirmationText = `የታክስ ማዕከል (Tax Center): ${taxpayerInfo.value.tax_center}`;
    const taxpayerNameText = `የታክስ ከፋይ ስም (Taxpayer Name): ${taxpayerInfo.value.taxpayer_name}`;
    const tinNumberText = `ግብር ከፋይ መለያ ቁጥር (TIN): ${taxpayerInfo.value.tin_number}`;
    const fiscalYearText = `የታክስ ዘመን (Tax Period): ${fiscalYear.value}`;
    const taxArrearsPeriodText = `የውዝፍ እዳ ዘመን (Tax arrears Period): ${(taxpayerInfo.value.fiscal_year - 1)}`;
    const paymentDateText = `የክፍያ ቀን (Payment Date): ${paymentDate.value}`;
    const paymentReasonText = `የክፍያ ምክንያት(Reason of Payment): የ${fiscalYear.value}${paymentReason.value}`;

    doc.text(certificateTitle, docWidth / 2, 30, { align: 'center' });
    doc.text(taxCenterText, docWidth / 2, 33, { align: 'center' });

    // Pairs side by side
    const leftColumnX = 10;
    const rightColumnX = docWidth / 2 + 10;
    let currentY = 50;
    const lineSpacing = 10;

    doc.text(taxpayerNameText, leftColumnX, currentY, { align: 'left' });
    doc.text(paymentCodeText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(tinNumberText, leftColumnX, currentY, { align: 'left' });
    doc.text(documentNumberText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(fiscalYearText, leftColumnX, currentY, { align: 'left' });
    doc.text(receiptNumberText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(taxArrearsPeriodText, leftColumnX, currentY, { align: 'left' });
    doc.text(taxCenterConfirmationText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(paymentDateText, leftColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;
    doc.text(paymentReasonText, leftColumnX, currentY, { align: 'left' });

    // Adding the table
    const taxAccountData = await fetchTaxAccountData();
    const tableColumn = [
      ["የሂሳብ መደብ (Account Code)", "ዴቢት (Debit)", "ክሬዲት (Credit)"]
    ];

    const tableRows = taxAccountData.map(row => [row.account_code, row.debit, row.credit]);

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      startY: currentY + 20,
      styles: { font: 'NotoSansEthiopic' }
    });

    const stumpimgData = stumplogo; // Use imported logo directly
    if (stumpimgData) {
      doc.addImage(stumpimgData, 'PNG', 120, 230, 40, 40);
    } else {
      console.error('Stump image data is missing');
    }

    // Generate Blob and download using FileSaver.js
    const blob = doc.output('blob');
    if (window.saveAs) {
      // Use FileSaver.js if available
      window.saveAs(blob, 'Tax_Confirmation_Receipt.pdf');
    } else {
      // Fallback to create an anchor and click it
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Tax_Confirmation_Receipt.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
  checkReceiptNumber.value = '';
}

async function generateQRCode() {
  try {
    const qrContent = `
      TIN Number: ${tinNumber.value}\n
      Name: ${taxpayername.value}\n
      Code: ${barcode.value}
    `;
    const qrCodeURL = await QRCode.toDataURL(qrContent); // Use actual data for the QR code
    qrCodeDataURL.value = qrCodeURL;
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
}






</script>



<style scoped>
.taxpayer-details-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.header-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.border-div {
  border: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 2px;
}

.form-column {
  width: 30%;
  margin-bottom: 20px;
}

.form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #555;
  margin-right: 10px;
  min-width: 140px;
  text-align: right;
  font-size: 16px;
}

span {
  padding: 8px 12px;
  color: #333;
  flex: 1;
}

.details-button:hover {
  background-color: #218838;
}

.details-info {
  margin-top: 30px;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.details-info h3 {
  margin-bottom: 10px;
  color: #007bff;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .form-column {
    width: 45%; /* Adjust width for medium screens */
  }
}

@media (max-width: 768px) {
  .form-column {
    width: 100%; /* Stack columns on small screens */
  }

  .form-item {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    text-align: left;
    margin-bottom: 5px;
  }

  span {
    width: 100%;
  }
}
</style>