<template>
 <div class="data-grid-container">
    <div class="grid-container-header"> 
    <h2 class="header-title">መልዕክቶች</h2>  
  </div>
    <!-- Notification Area -->
    <div v-if="notification.visible" class="notification">
      {{ notification.message }}
    </div>

    <!-- Existing Message List -->
    <div class="message-list">
      <!-- Column Headers -->
      <div class="message-header">
        <span class="header-field">Subject</span>
        <span class="header-field">Sender</span>
        <span class="header-field">Sent Date</span>
        <span class="header-field">Frequency</span>
        <span class="header-field">Status</span>
      </div>

      <!-- Message Rows -->
      <ul class="message-rows" style="padding-left: 10px;">
        <li 
          v-for="(message, index) in messages" 
          :key="message.id" 
          class="message-row"
          :class="{ 'selected': showDetails[index] }"
        >
          <div 
            @click="toggleDetail(index)" 
            class="message-summary" 
            :class="{ 'unread': message.message_read_status === 'unread' }"
          >
            <!-- Fixed columns -->
            <span class="message-field fixed-column">{{ message.message_subject }}</span>
            <span class="message-field fixed-column">{{ message.message_sender }}</span>
            <span class="message-field">{{ message.sent_date }}</span>
            <span class="message-field">{{ message.message_sent_frequency }}</span>
            <span class="message-field">{{ message.message_read_status }}</span>
          </div>

          <transition name="fade">
            <div v-if="showDetails[index]" class="message-detail">
              <p>Message Content:</p>
              <textarea rows="4" cols="100" :value="message.message_content" readonly></textarea>
            </div>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/axios-config';


const messages = ref([]);
const showDetails = ref([]);
const notification = ref({ visible: false, message: '' });

const markMessageAsRead = async (messageId) => {
  try {
    await axiosInstance.post('api/tpservice/taxpayer_service/update-message-status/', {
      message_id: messageId
    });
  } catch (error) {
    console.error('Error updating message status:', error);
  }
};

const toggleDetail = (index) => {
  showDetails.value[index] = !showDetails.value[index];

  if (messages.value[index].message_read_status === 'unread') {
    messages.value[index].message_read_status = 'read';

    markMessageAsRead(messages.value[index].message_id);
  }
};

const fetchMessages = async () => {
  try {
    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_messages/`);
    messages.value = response.data;
    showDetails.value = messages.value.map(() => false);
  } catch (error) {
    console.error('Error fetching messages:', error);
  }
};

onMounted(() => {
  fetchMessages();
});
</script>

<style>
.taxpayer-details-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* max-width: 1200px; */
  margin: auto;

}
.header-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;

}
.border-div {
  border: 1px solid #ccc;
  /* padding: 10px; */
  /* background-color: #fff; */
  /* width: -moz-available; */
  /* margin: 10px; */
  /* border-radius: inherit; */

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}


.notification {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.message-list {
  margin-top: 5px; /* Push the message list down to avoid overlap with the notification */
  width: 100%;
  overflow-x: auto;
}

.message-header, .message-summary {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 2px solid #e0e0e0;
}

.message-header {
  font-weight: bold;
  padding: 10px;
  padding-left: 20px;
}

.header-field, .message-field {
  flex: 1;
  text-align: left;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
  white-space: nowrap;
}

.message-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.message-summary {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  width: 100%;
}

.message-summary.unread {
  font-weight: bold;
}

.message-row.selected .message-summary {
  background-color: #e0f7fa;
}

.fixed-column {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 1;
  border-right: 1px solid #e0e0e0;
}

.message-detail {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  margin-top: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
