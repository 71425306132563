<template>
 <div class="taxpayer-details-card">
    <div class="border-div">
        <h2 class="header-title">የክፍያ ደረሰኝ አውርድ</h2>  
    </div>   
    <div class="form-group">
      <div class="form-column">
        <div class="form-item">
          <label for="tin">TIN:</label>
          <span id="tin">{{ taxpayerInfo.tin_number }}</span>
        </div>
        <div class="form-item">
          <label for="name">Full Name:</label>
          <span id="name">{{ taxpayerInfo.taxpayer_name }}</span>
        </div>

   
      </div>

      <div class="form-column">
   
        <!-- <div class="form-item">
          <label for="email">Email Address:</label>
          <span id="email">{{ taxpayerInfo.email_address }}</span>
        </div> -->
        <div class="form-item">
          <label for="tax_center">Tax Center:</label>
          <span id="tax_center">{{ taxpayerInfo.tax_center }}</span>
        </div>
        <div class="form-item">
          <label for="business_activity">Business Name:</label>
          <span id="business_activity">{{ taxpayerInfo.trade_name }}</span>
        </div>
 
      </div>

      <div class="form-column">
        <div class="form-item">
          <label for="fiscal_year">Fiscal Year:</label>
          <span id="fiscal_year">{{ taxpayerInfo.fiscal_year }}</span>
        </div>
        <!-- <div class="form-item">
          <label for="taxpayer_category">Taxpayer Category:</label>
          <span id="taxpayer_category">{{ taxpayerInfo.taxpayer_category_name }}</span>
        </div> -->
 


      </div>
    </div>
    <div class="combo-year">
      <div>
        <label for="fiscalYearSelect" class="select-label">Select Fiscal Year</label>
        <DxSelectBox
          width="200px"
          :items="selectOptions1"
          v-model="fiscalYear"
          placeholder="Choose Fiscal Year"
          caption="Select Fiscal Year"
        />
      </div>

      
    </div>

    <div v-if="showPreview" style="padding: 10px; ">
    <DxButton 
      text=" Download Receipt " 
      @click="generatePDF" 
      :disabled="!receiptNumber"
      
    />
  </div>
  </div>
</template> 

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/axios-config';
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { DxSelectBox, DxButton } from 'devextreme-vue';
import logo from '@/assets/aborlogo.png';
import stumplogo from '@/assets/aborstump.png';
import NotoSansEthiopic from '@/assets/fonts/NotoSansEthiopic-Regular.ttf';

const taxpayerId = 571368;

const taxpayerInfo = ref({
  // Add default values if needed
});

const showPreview = ref(true);
const letterNumber = ref('abor-01/2017');
const documentNumber = ref('');
const receiptNumber = ref(null);
const taxCenter = ref('');
const taxpayername = ref('');
const tinNumber = ref('');
const tradeName = ref('');
const fiscalYear = ref('');
const licenceRenewalYear = ref('');
const qrCodeDataURL = ref('');
const selectOptions1 = ref([2014,2015, 2016]);
const municipalityPaidAmountText = ref(''); // Add missing variables
const municipalityTotalAmountText = ref(''); // Add missing variables
const stateTaxTotalAmountText = ref(''); // Add missing variables
const stateTaxPaidAmountText = ref(''); // Add missing variables
const stateTaxBillCodeText = ref(''); // Add missing variables
const municipalityBillCodeText = ref(''); // Add missing variables
const paymentReason = ref(' አመታዊ የስራ ግብር ክፍያ');
const paymentDate = ref('');
const taxArrearsPeriod=ref('');
const receiptNumberGeneratedDate=ref('');
const varcode=ref('');
// Fetch taxpayer info on component mount
onMounted(() => {
  fetchTaxpayerInfo();
});

// Fetch taxpayer info
async function fetchTaxpayerInfo() {
  try {
  
    const token = getCookie('access_token');

if (token) {
    const decodedToken = jwtDecode(token);
    // const taxpayerId = decodedToken.user_id;
    // const userRoles = decodedToken.roles; // Adjust according to your token structure

    console.log(decodedToken);
    // console.log('Roles:', userRoles);
} else {
    console.log('No token found');
}


    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_info/${taxpayerId}/`);
    const taxpayerInfoData = response.data;

    if (taxpayerInfoData.length > 0) {
      const taxpayerData = taxpayerInfoData[0];
      taxpayerInfo.value = taxpayerData;

      taxCenter.value = taxpayerData.tax_center || '';
      taxpayername.value = taxpayerData.taxpayer_name || '';
      tinNumber.value = taxpayerData.tin_number || '';
      tradeName.value = taxpayerData.trade_name || '';
      fiscalYear.value = taxpayerData.fiscal_year || '';
      licenceRenewalYear.value = taxpayerData.licence_renewal_year || '';
      letterNumber.value = taxpayerData.letter_number || '';
      receiptNumber.value = taxpayerData.receipt_number || '';
      documentNumber.value = taxpayerData.document_number || '';
      municipalityPaidAmountText.value = taxpayerData.municipality_paid_amount || '';
      municipalityTotalAmountText.value = taxpayerData.municipality_fee_total || '';
      stateTaxTotalAmountText.value = taxpayerData.state_tax_total || '';
      stateTaxPaidAmountText.value = taxpayerData.state_tax_paid_amount || '';
      stateTaxBillCodeText.value = taxpayerData.taxpayer_state_tax_bill_code || '';
      municipalityBillCodeText.value = taxpayerData.taxpayer_municipality_fee_bill_code || '';
      paymentDate.value = taxpayerData.payment_date || '';
      taxArrearsPeriod.value=taxpayerData.fiscal_year-1;
      receiptNumberGeneratedDate.value=taxpayerData.generated_date || '';
      varcode.value=taxpayerData.receipt_varcode || '';   
    }
    else {
      alert('No taxpayer information found.');
    }
  } catch (error) {
    console.error('Error fetching taxpayer info:', error);
  }
}

// Fetch tax account data
async function fetchTaxAccountData() {

  const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_payment/${taxpayerId}/`);
  return response.data;
}

// Generate PDF
async function generatePDF() {
  try {
    const response = await fetch(NotoSansEthiopic);
    const font = await response.arrayBuffer();
    const fontData = btoa(String.fromCharCode(...new Uint8Array(font)));

    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.setLineHeightFactor(1);
    doc.addFileToVFS('NotoSansEthiopic-Regular.ttf', fontData);
    doc.addFont('NotoSansEthiopic-Regular.ttf', 'NotoSansEthiopic', 'normal');
    doc.setFont('NotoSansEthiopic');
    const docWidth = doc.internal.pageSize.width;
    const pageWidth = doc.internal.pageSize.getWidth();
    const qrCodeSize = 20;

    if (!qrCodeDataURL.value) {
      await generateQRCode();
    }
    doc.addImage(qrCodeDataURL.value, 'PNG', pageWidth - qrCodeSize - 10, 10, qrCodeSize, qrCodeSize);

    const imgData = logo;
    if (imgData) {
      doc.addImage(imgData, 'PNG', 10, 10, 60, 20);
    } else {
      console.error('Logo image data is missing');
    }

    const taxCenterText = `\nየ ${taxCenter.value} ገቢዎች መምሪያ/ጽ/ቤት`;
    const certificateTitle = 'የኤሌክትሮኒክስ ክፍያ ደረሰኝ\n';
    const paymentCodeText = `የመክፈያ ኮድ(Payment Code):M${municipalityBillCodeText.value}/S${stateTaxBillCodeText.value}`;
    const documentNumberText = `የዶክመንት ቁጥር (Document Number): ${documentNumber.value}`;
    const receiptNumberText = `የደረሰኝ ቁጥር (Receipt Number): ${receiptNumber.value}`;
    const taxCenterConfirmationText = `የታክስ ማዕከል (Tax Center): ${taxCenter.value}`;
    
    const taxpayerNameText = `የታክስ ከፋይ ስም (Taxpayer Name): ${taxpayername.value}`;
    const tinNumberText = `ግብር ከፋይ መለያ ቁጥር (TIN): ${tinNumber.value}`;
    const fiscalYearText = `የታክስ ዘመን (Tax Period): ${fiscalYear.value}`;
    const taxArrearsPeriodText = `የውዝፍ እዳ ዘመን (Tax arrears Period): ${taxArrearsPeriod.value}`;
    const paymentDateText = `የክፍያ ቀን (Payment Date): ${paymentDate.value}`;
    const paymentReasonText = `የክፍያ ምክንያት(Reason of Payment): ${paymentReason.value}`;

    doc.text(certificateTitle, docWidth / 2, 30, { align: 'center' });
    doc.text(taxCenterText, docWidth / 2, 33, { align: 'center' });

    // Pairs side by side
    const leftColumnX = 10;
    const rightColumnX = docWidth / 2 + 10;
    let currentY = 50;
    const lineSpacing = 10;

    doc.text(taxpayerNameText, leftColumnX, currentY, { align: 'left' });
    doc.text(paymentCodeText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(tinNumberText, leftColumnX, currentY, { align: 'left' });
    doc.text(documentNumberText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(fiscalYearText, leftColumnX, currentY, { align: 'left' });
    doc.text(receiptNumberText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(taxArrearsPeriodText, leftColumnX, currentY, { align: 'left' });
    doc.text(taxCenterConfirmationText, rightColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;

    doc.text(paymentDateText, leftColumnX, currentY, { align: 'left' });
    currentY += lineSpacing;
    doc.text(paymentReasonText, leftColumnX, currentY, { align: 'left' });

    // Adding the table
    const taxAccountData = await fetchTaxAccountData();
        const tableColumn = [
          ["የሂሳብ መደብ (Account Code)", "ዴቢት (Debit)", "ክሬዲት (Credit)"]
        ];

        const tableRows = taxAccountData.map(row => [row.account_code, row.debit, row.credit]);

        doc.autoTable({
          head: tableColumn,
          body: tableRows,
          startY: currentY + 20,
          styles: { font: 'NotoSansEthiopic' }
        });

        const stumpimgData = stumplogo; // Use imported logo directly
        if (stumpimgData) {
          doc.addImage(stumpimgData, 'PNG', 120, 230, 40, 40);
        } else {
          console.error('Stump image data is missing');
        }

    // Finalize and save the PDF
    doc.save('Tax_Confirmation_Reciept.pdf');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
}




async function generateQRCode() {
  try {
    const qrContent = `
      TIN Number: ${tinNumber.value} \n 
      Name: ${taxpayername.value}\n
      Code: ${varcode.value}

    `;
    const qrCodeURL = await QRCode.toDataURL(qrContent); // Use actual data for the QR code
    qrCodeDataURL.value = qrCodeURL;
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
}


// import jwtDecode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';



function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}
// Assuming the token is stored in cookies or local storage
const token = getCookie('access_token') || localStorage.getItem('access_token');

if (token) {
  const decodedToken = jwtDecode(token);
  const _taxpayerId = decodedToken.user_id;
  const userRoles = decodedToken.roles; // Adjust according to your token structure
  
  // Now you can use taxpayerId and roles
  console.log('Taxpayer ID:', _taxpayerId);
  console.log('Roles:', userRoles);
} else {
  console.log('No token found');
}



</script>






<style scoped>


.taxpayer-details-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* max-width: 1200px; */
  margin: auto;
  
}

.header-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;

}

.border-div {
  border: 1px solid #ccc;
  /* padding: 10px; */
  /* background-color: #fff; */
  /* width: -moz-available; */
  /* margin: 10px; */
  /* border-radius: inherit; */
  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}


.form-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	border: 1px solid #ccc;
	padding: 10px;
	margin-top: 2px;
}

.form-column {
  width: 30%;
  margin-bottom: 20px;
}

.form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #555;
  margin-right: 10px;
  min-width: 140px; /* Adjust this value as needed */
  text-align: right; /* Right-align the labels */
  font-size: 16px;
}

span {
  padding: 8px 12px;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  /* background-color: #f9f9f9; */
  color: #333;
  flex: 1;
  font-size: 16px;
}

/* .details-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

.details-button:hover {
  background-color: #218838;
}

.details-info {
  margin-top: 30px;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.details-info h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.input {
  width: 100%;
  margin-bottom: 10px;
}

.combo-year {
  flex:1;
  padding: 10px;
}

#generate-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


</style> 
