<template>
  <div class="dashboard-container">
    <div class="row">
      <!-- State Tax Card -->
      <div class="card tax-card state-tax-card">
        <div class="card-header">
          <h2>State Tax</h2>
          <p class="fiscal-year">Fiscal Year: {{ fiscalYear }}</p>
        </div>
        <div class="card-body">
          <div class="tax-amount">
            <p class="label">Total:</p>
            <p class="amount">{{ formattedStateTaxTotal }}</p>
          </div>
          <div class="tax-amount">
            <p class="label">Paid:</p>
            <p class="amount">{{ formattedStateTaxPaid }}</p>
          </div>
          <div class="chart-container">
            <canvas id="stateTaxChart"></canvas>
          </div>
        </div>
      </div>

      <!-- Municipality Fee Card -->
      <div class="card tax-card municipality-fee-card">
        <div class="card-header">
          <h2>Municipality Fee</h2>
          <p class="fiscal-year">Fiscal Year: {{ fiscalYear }}</p>
        </div>
        <div class="card-body">
          <div class="tax-amount">
            <p class="label">Total:</p>
            <p class="amount">{{ formattedMunicipalityFeeTotal }}</p>
          </div>
          <div class="tax-amount">
            <p class="label">Paid:</p>
            <p class="amount">{{ formattedMunicipalityFeePaid }}</p>
          </div>
          <div class="chart-container">
            <canvas id="municipalityFeeChart"></canvas>
          </div>
        </div>
      </div>

      <!-- Total Amount Card -->
      <div class="card tax-card total-tax-card">
        <div class="card-header">
          <h2>Total Tax Amount</h2>
          <p class="fiscal-year">Fiscal Year: {{ fiscalYear }}</p>
        </div>
        <div class="card-body">
          <div class="tax-amount">
            <p class="label">Total Expected:</p>
            <p class="amount">{{ formattedTotalExpected }}</p>
          </div>
          <div class="tax-amount">
            <p class="label">Total Paid:</p>
            <p class="amount">{{ formattedTotalPaid }}</p>
          </div>
          <div class="chart-container">
            <canvas id="totalTaxChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Notifications and Messages -->
    <div class="row">

      <div class="card payment-date-card">
        <div class="card-header">
          <h2>የመክፍያ ጊዜ</h2>
        </div>
        <div class="tax-date">
          <p class="amount">ከ {{ taxPaymentStartDate }}</p>
        </div>
        <div class="tax-date">
          <p class="amount">እስከ {{ taxPaymentLastDate }}</p>
        </div>
      </div>

      <div class="card notification-card">
        <div class="card-header">
          <h2>Notifications</h2>
        </div>
        <div class="notification-card p">

          <p :class="{ 'glow-animation': countUnreadMessages > 0 }">
            Unread Messages: {{ countUnreadMessages }}
          </p>
          <!-- <DxList :dataSource="notifications" height="200px">
            <template #item="{ data }">
              <div class="notification-item">
                <p class="notification-title">{{ data.title }}</p>
                <p class="notification-content">{{ data.content }}</p>
              </div>
            </template>
          </DxList> -->
        </div>
      </div>
    <div class="card message-card">
      <div class="card-header">
        <h2>Notice</h2>
      </div>
      <DxList :dataSource="notice" height="200px">
        <template #item="{ data }">
          <div class="message-item">
            <p class="message-content">{{ data.content }}</p>
          </div>
        </template>
      </DxList>
    </div>
    
  </div>

  <!-- Contact Information -->
  <div class="row">
    <div class="card contact-card">
      <div class="card-header">
        <h2>Contact Information</h2>
      </div>
      <div class="card-body contact-info">
        <p><strong>Email:</strong> etas_support@abor.gov.et</p>
        <p><strong>Phone:</strong> (251) 000000-0000</p>
        <p><strong>Address:</strong> Bahir Dar</p>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>

import { ref, onMounted, computed } from 'vue';
import { DxList } from 'devextreme-vue';
import axiosInstance from '@/axios-config';
import Chart from 'chart.js/auto';


const stateTaxTotal = ref(0);
const stateTaxPaid = ref(0);
const municipalityFeeTotal = ref(0);
const municipalityFeePaid = ref(0);
// const notifications = ref([]);
const messages = ref([]);
// const newMessage = ref('');
const fiscalYear = ref([]);
const countUnreadMessages = ref([0]);

const taxPaymentStartDate = ref('');
const taxPaymentLastDate = ref('');

const loading = ref(true); // Loading state


const fetchData = async () => {
  loading.value = true; // Set loading state to true
  try {
    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_info/`);
    const data = response.data[0];
    stateTaxTotal.value = data.state_tax_total;
    stateTaxPaid.value = data.state_tax_paid_amount;
    municipalityFeeTotal.value = data.municipality_fee_total;
    municipalityFeePaid.value = data.municipality_tax_paid_amount;
    fiscalYear.value = data.fiscal_year;
    taxPaymentStartDate.value='ሐምሌ, 1 2016';
    taxPaymentLastDate.value='ሐምሌ, 30 2016';   //data.payment_date;

    // Initialize charts after data is set
    initializeCharts();
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // Set loading state to false
  }
};

// const fetchNotifications = async () => {
//   try {
//     const response = await axiosInstance.get('api/notifications/');
//     notifications.value = response.data;
//   } catch (error) {
//     console.error('Error fetching notifications:', error);
//   }
// };

const fetchNotifications = async () => {
  try {
    const response = await axiosInstance.get(`api/tpservice/taxpayer_service/taxpayer_messages/`);
    messages.value = response.data;
    // Count unread messages
    const unreadCount = messages.value.filter(message => message.message_read_status=='unread').length;
    countUnreadMessages.value = unreadCount;
    // console.log(unreadCount);
    
  } catch (error) {
    console.error('Error fetching messages:', error);
  }
};



const initializeCharts = () => {
  // State Tax Chart
  const stateTaxChartCtx = document.getElementById('stateTaxChart').getContext('2d');
  new Chart(stateTaxChartCtx, {
    type: 'doughnut',
    data: {
      labels: ['Paid', 'Unpaid'],
      datasets: [{
        data: [stateTaxPaid.value, stateTaxTotal.value - stateTaxPaid.value],
        backgroundColor: ['#4caf50', '#f44336'],
        hoverBackgroundColor: ['#66bb6a', '#ef5350'],
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            font: {
              size: 12,
            },
            color: '#2c3e50',
          }
        }
      }
    }
  });

  // Municipality Fee Chart
  const municipalityFeeChartCtx = document.getElementById('municipalityFeeChart').getContext('2d');
  new Chart(municipalityFeeChartCtx, {
    type: 'doughnut',
    data: {
      labels: ['Paid', 'Unpaid'],
      datasets: [{
        data: [municipalityFeePaid.value, municipalityFeeTotal.value - municipalityFeePaid.value],
        backgroundColor: ['#2196f3', '#ffc107'],
        hoverBackgroundColor: ['#42a5f5', '#ffca28'],
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            font: {
              size: 12,
            },
            color: '#2c3e50',
          }
        }
      }
    }
  });

  // Total Tax Chart
  const totalTaxChartCtx = document.getElementById('totalTaxChart').getContext('2d');
  new Chart(totalTaxChartCtx, {
    type: 'doughnut',
    data: {
      labels: ['Paid', 'Unpaid'],
      datasets: [{
        data: [
          stateTaxPaid.value + municipalityFeePaid.value,
          (stateTaxTotal.value + municipalityFeeTotal.value) - (stateTaxPaid.value + municipalityFeePaid.value)
        ],
        backgroundColor: ['#4caf50', '#f44336'],
        hoverBackgroundColor: ['#66bb6a', '#ef5350'],
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            font: {
              size: 12,
            },
            color: '#2c3e50',
          }
        }
      }
    }
  });
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'ETB' }).format(amount);
};

const formattedStateTaxTotal = computed(() => formatCurrency(stateTaxTotal.value));
const formattedStateTaxPaid = computed(() => formatCurrency(stateTaxPaid.value));
const formattedMunicipalityFeeTotal = computed(() => formatCurrency(municipalityFeeTotal.value));
const formattedMunicipalityFeePaid = computed(() => formatCurrency(municipalityFeePaid.value));

const formattedTotalExpected = computed(() => {
  const totalExpected = Number(stateTaxTotal.value) + Number(municipalityFeeTotal.value);
  return formatCurrency(totalExpected);
});

const formattedTotalPaid = computed(() => {
  const totalPaid = Number(stateTaxPaid.value) + Number(municipalityFeePaid.value);
  return formatCurrency(totalPaid);
});

onMounted(() => {
  fetchData();
  fetchNotifications();
  // fetchMessages();
});
</script>




<style>
.dashboard-container {
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  /* Allows wrapping for smaller screens */
}

.card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  flex: 1;
  min-width: 350px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-header {
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  /* margin-bottom: 15px; */
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.fiscal-year {
  font-size: 1rem;
  color: #777;
}

.card-body {
  font-size: 1rem;
  color: #555;
}

.tax-card {
  text-align: center;
}

.tax-card.state-tax-card {
  border-left: 6px solid #4caf50;
}

.tax-card.municipality-fee-card {
  border-left: 6px solid #2196f3;
}

.tax-card.total-tax-card {
  border-left: 6px solid #ff5722;
}

.tax-amount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}


.tax-amount .label {
  font-weight: bold;
  color: #757575;
}

.tax-amount .amount {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
}

.tax-date .amount {
  font-size: 1.6rem;
  font-weight: bold;
  color: #2c3e50;
  align-items: center;
}

.chart-container {
  width: 100%;
  height: 100px;
  /* Increased height for better visibility */
  margin-top: 15px;
}

.notification-card p {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* Center-align text */
  font-size: 1.2rem; /* Increase font size for better visibility */
  padding: 10px;
}

/* Glowing Animation */
@keyframes glow {
  0% { text-shadow: 0 0 5px #ff0, 0 0 10px #ff0, 0 0 15px #ff0, 0 0 20px #ff0, 0 0 25px #ff0, 0 0 30px #ff0, 0 0 35px #ff0; }
  50% { text-shadow: 0 0 10px #ff0, 0 0 20px #ff0, 0 0 30px #ff0, 0 0 40px #ff0, 0 0 50px #ff0, 0 0 60px #ff0, 0 0 70px #ff0; }
  100% { text-shadow: 0 0 5px #ff0, 0 0 10px #ff0, 0 0 15px #ff0, 0 0 20px #ff0, 0 0 25px #ff0, 0 0 30px #ff0, 0 0 35px #ff0; }
}

.glow-animation {
  animation: glow 1.5s ease-in-out infinite;
  color: #ff0; /* Text color to match the glow */
}
.notification-item,
.message-item {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.notification-item:hover,
.message-item:hover {
  background-color: #f1f1f1;
}

.notification-title {
  font-weight: bold;
  color: #333;
}

.notification-content,
.message-content {
  color: #666;
}

.contact-card .contact-info {
  font-size: 1rem;
  color: #333;
  align-items: center;
}



.notification {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.message-list {
  margin-top: 50px;
  /* Push the message list down to avoid overlap with the notification */
  width: 100%;
  overflow-x: auto;
}

/* Existing styles */
.message-header,
.message-summary {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 2px solid #e0e0e0;
}

.message-header {
  font-weight: bold;
  padding: 10px;
  padding-left: 20px;
}

.header-field,
.message-field {
  flex: 1;
  text-align: left;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
  white-space: nowrap;
}

.message-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.message-summary {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  width: 100%;
}

.message-summary.unread {
  font-weight: bold;
}

.message-row.selected .message-summary {
  background-color: #e0f7fa;
}

.fixed-column {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 1;
  border-right: 1px solid #e0e0e0;
}

.message-detail {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  margin-top: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Add these styles within your <style> block or in your CSS file */
.payment-date-card {
  border-left: 6px solid #ff9800;
  /* Orange border to make it stand out */
  background-color: #fff3e0;
  /* Light orange background color */
}

.payment-date-card .card-header {
  background-color: #ffa726;
  /* Slightly darker orange for header */
  color: white;
  /* White text color for contrast */
  border-bottom: none;
  /* Remove the default border */
  border-radius: 12px 12px 0 0;
  /* Rounded corners for the header */
  padding: 15px;
  /* Extra padding for a more prominent header */
  text-align: center;
  /* Center align the header text */
}

.payment-date-card .tax-date .amount {
  font-size: 1.5rem;
  /* Slightly smaller font size for the date */
  font-weight: bold;
  /* Bold font */
  color: #e65100;
  /* Dark orange text color */
  text-align: center;
  /* Center align the dates */
  /* margin: 10px 0; */
  /* Add some spacing around the date */
}

.payment-date-card .card-body {
  padding: 20px;
  /* More padding for the card body */
}
.content {
	line-height: 0.5;
}
.content h2 {
  font-size:28px;
}
</style>
