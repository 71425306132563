<template>
    <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
      <div class="dx-card content">
        <div class="header">
          <img src="@/assets/logo.png" alt="Company Logo" class="logo" />
          <div class="header-title" v-html="headerTitle"></div>
          <div class="description"> Electronic Tax Administration System (eTAS) </div>
        </div>
        <hr class="divider" />
        <slot />
      </div>
    </dx-scroll-view>
  </template>
  
  <script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import { useRoute } from 'vue-router';
  import { watch, ref } from 'vue';
  
  export default {
    name: 'CustomLayout',
    components: {
      DxScrollView
    },
    setup() {
      const route = useRoute();
  
      const headerTitle = ref(route.meta.title);
      const description = ref("");
  
      watch(() => route.path, () => {
        headerTitle.value = route.meta.title;
        description.value = route.meta.description;
      });
  
      return {
        headerTitle,
        description
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
@import "../themes/generated/variables.base.scss";

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 400px;
    margin: auto;
    padding: 40px;
    flex-grow: 0;
    border-radius: 15px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0; 
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center; 
      margin-bottom: 5px;

      .logo {
        padding-top: 6px;
        max-height: 100%;
        max-width: 100%;
        height: 90%;
        padding-bottom: 10px;
        width: 100px;
        margin-left: 0px;
      }

      .header-title {
        color:rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 28px;
        font-weight: 300;
        font-size: 24px;
        margin-top: 5px; 
        text-align: center;
        font-weight: bold;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 22px;
        padding-top: 10px;
        font-size: 18px;
        text-align: center; // Center description
        font-weight: bold;
      }
    }

    .divider {
      border: 0; 
      height: 1px; 
      background-color: rgba($base-text-color, alpha($base-text-color) * 0.7);
      margin: 20px 0; 
      width: 100%; 
    }
  }
}
</style>
