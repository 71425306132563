
export const API_URL = 'https://etas.abor.gov.et/';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import auth from './auth'


import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, 
});

axiosInstance.interceptors.request.use(
  (config) => {
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
    if (csrfMetaTag) {
      const csrfToken = csrfMetaTag.getAttribute('content');
      if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
      }
    } 
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {

        const response = await axios.get(`${API_URL}api/tpservice/authsystaxpayer/check-auth/`, {
          withCredentials: true
        });
        if (response.status === 200) {
          await auth.logOut(); 
          router.push({ name: 'login-form', query: { redirect: route.query.redirect || "/home" } });
        }
        else{
          await auth.clearCookie();
          notify({ type: 'error', message: 'Your session expired. Please re-login.', displayTime: 2000 });
          router.push({ name: 'login-form', query: { redirect: route.query.redirect || "/home" } });
        }
      } catch (logoutError) {
        await auth.clearCookie();
        notify({ type: 'error', message: 'An error occurred. Please re-login.', displayTime: 2000 });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
